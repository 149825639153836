var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {}),
        (_obj[_vm.$options.css["footer-type-2"]] = _vm.footerType === 1),
        _obj),
    },
    [
      _c("div", { class: _vm.$options.css["apps-title"] }, [_vm._v("Apps")]),
      _vm._v(" "),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: _vm.$options.css["box-item"],
            on: {
              click: function ($event) {
                return _vm.goDl(index + 1)
              },
            },
          },
          [
            _c(
              "div",
              { class: _vm.$options.css["img"] },
              [
                item.img
                  ? _c("LazyImage", {
                      attrs: { src: _vm.$imgSrcBase + "/imgs/" + item.img },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(item.text))]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }