<template>
  <div :class="{[$options.css['footer-type-2']]: footerType === 1}">
    <div :class="$options.css['apps-title']">Apps</div>
    <div
      v-for="(item, index) in list"
      :key="index"
      :class="$options.css['box-item']"
      @click="goDl(index + 1)"
    >
      <div :class="$options.css['img']">
        <LazyImage
          v-if="item.img"
          :src="`${$imgSrcBase}/imgs/${item.img}`"
        />
      </div>
      <p>{{item.text}}</p>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { useRouter } from '@/utils/hooks';
import css from './AppsBox.scss';

export default {
  props: {
    footerType: { type: Number, required: true },
  },
  setup(props) {
    const router = useRouter();

    const goDl = switchActive => {
      router.push({
        name: 'Apps',
        params: {
          switchActive,
        },
      });
    };

    const list = computed(() => {
      const { footerType } = props;
      const arr = [
        // {
        //   text: 'iPhone/iPad',
        //   img1: 'download/download_ios_1.svg',
        //   img2: 'download/download_ios_2.svg',
        // },
        {
          text: 'Android',
          img1: 'download/download_android_1.svg',
          img2: 'download/download_android_2.svg',
        },
        {
          text: 'Windows',
          img1: 'download/download_windows_1.svg',
          img2: 'download/download_windows_2.svg',
        },
        {
          text: 'Mac',
          img1: 'download/download_mac_1.svg',
          img2: 'download/download_mac_2.svg',
        },
        {
          text: 'Linux',
          img1: 'download/download_linux_1.svg',
          img2: 'download/download_linux_2.svg',
        },
      ];

      return footerType === 0
        ? arr.map(({ text, img2 }) => ({ text, img: img2 }))
        : arr.map(({ text, img1 }) => ({ text, img: img1 }));
    });

    return {
      list,
      goDl,
    };
  },
  css,
};
</script>
